import React, { useMemo } from 'react';
import { FormViewerContext } from '@wix/form-viewer/widget';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { useCheckoutLoader } from '../../../hooks/useCheckoutLoader';
import { AppWidgetProps } from '../../../types/common';
import { CheckoutLoader } from './CheckoutLoader';
import { Modal } from './Modal';
import { PackagePickerWidgetContext } from './PackagePickerWidgetContext';

const LazyList = loadable(() => import('./List'));
const LazyStatus = loadable(() => import('./Status'));
const LazyCheckout = loadable(() => import('./Checkout'));
const LazyRestricted = loadable(() => import('./Restricted'));

const Widget: React.FC<AppWidgetProps> = React.memo((props) => {
  const { isMobile } = useEnvironment();
  const width = props.host.dimensions.width ?? (isMobile ? 320 : 980);
  return (
    <PackagePickerWidgetContext locale={props.locale} width={width}>
      <FormViewerContext {...props}>
        <ViewSwitch {...props} />
      </FormViewerContext>
      <Modal {...props} />
    </PackagePickerWidgetContext>
  );
});

const ViewSwitch: React.FC<AppWidgetProps> = React.memo((props) => {
  const { subPage, navigateToHomePage, isCheckoutDataInitialized } = props;
  const { shouldShowLoader, setIsCheckoutComponentLoaded } = useCheckoutLoader({
    isCheckoutDataInitialized,
  });
  const emptyState = useMemo(() => ({ isInWidget: false, onClick: () => navigateToHomePage() }), [navigateToHomePage]);

  switch (subPage.name) {
    case 'list':
    default:
      return <LazyList emptyState={emptyState} {...props} />;
    case 'checkout':
      return (
        <CheckoutLoader loading={shouldShowLoader}>
          <LazyCheckout
            {...props}
            onLoad={() => setIsCheckoutComponentLoaded(true)}
            viewMode={props.host.viewMode}
            siteStyles={props.host.style}
            integrationData={subPage.checkoutData.integrationData}
          />
        </CheckoutLoader>
      );

    case 'status':
      return (
        <LazyStatus
          {...props}
          ownerDemo={subPage.statusData.ownerDemo}
          integrationData={subPage.statusData.purchaseData.checkoutData.integrationData}
        />
      );
    case 'restricted':
      return <LazyRestricted {...props} />;
  }
});

export default Widget;
